import React from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";

import teamImg1 from "../../assets/images/team/team-01.jpg";
import teamImg2 from "../../assets/images/team/team-02.jpg";
import teamImg3 from "../../assets/images/team/team-03.jpg";
import teamImg4 from "../../assets/images/team/team-07.jpg";
import teamImg5 from "../../assets/images/team/team-05.jpg";
import teamImg6 from "../../assets/images/team/team-06.jpg";
import teamImg7 from "../../assets/images/team/team-11.jpg";
import teamImg8 from "../../assets/images/team/team-09.jpg";

let data = [
  {
    images: teamImg1,
    title: "Jone Due",
    designation: "Sr. Web Developer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
  },
  {
    images: teamImg2,
    title: "Fatima Asrafi",
    designation: "Front-end Engineer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
    ],
  },
  {
    images: teamImg3,
    title: "John Dou",
    designation: "Sr. Graphic Designer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
  },
  {
    images: teamImg6,
    title: "Jone Due",
    designation: "Sr. Web Developer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
  },
  {
    images: teamImg5,
    title: "Fatima Asrafi",
    designation: "Front-end Engineer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
    ],
  },
  {
    images: teamImg6,
    title: "Fatima Amar",
    designation: "Sr. Graphic Designer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
  },
  {
    images: teamImg4,
    title: "Fatima Amar",
    designation: "Sr. Graphic Designer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
  },
  {
    images: teamImg7,
    title: "John Dou",
    designation: "Sr. Graphic Designer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
  },
  {
    images: teamImg8,
    title: "John Dou",
    designation: "Sr. Graphic Designer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
  },
  {
    images: teamImg7,
    title: "John Dou",
    designation: "Sr. Graphic Designer",
    socialNetwork: [
      {
        icon: <FaFacebookF />,
        url: "#",
      },
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
    ],
  },
];

export default data;
