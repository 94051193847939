import React, { Component } from "react";

import framestoreLogo from "../assets/images/eriks/Framestore_logo.png";
import allThingsMediaLogo from "../assets/images/eriks/atm_logo.png";
import leftFieldLabsLogo from "../assets/images/eriks/LFL_Logo.jpg";
import meowWolfLogo from "../assets/images/eriks/mw_logo.png";
import electricPlayhouseLogo from "../assets/images/eriks/electricplayhouse_logo.gif";
import treeLogo from "../assets/images/eriks/tree_logo.jpg";
import vitaMotusLogo from "../assets/images/eriks/VitaMotus_Logo.jpg";
import teamRolfesLogo from "../assets/images/eriks/TeamRolfes_logo.png";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img src={framestoreLogo} alt="Logo Images" />
          </li>
          <li>
            <img src={allThingsMediaLogo} alt="Logo Images" />
          </li>
          <li>
            <img src={leftFieldLabsLogo} alt="Logo Images" />
          </li>
          <li>
            <img src={meowWolfLogo} alt="Logo Images" />
          </li>
          <li>
            <img src={electricPlayhouseLogo} alt="Logo Images" />
          </li>
          <li>
            <img src={treeLogo} alt="Logo Images" />
          </li>
          <li>
            <img src={vitaMotusLogo} alt="Logo Images" />
          </li>
          <li>
            <img src={teamRolfesLogo} alt="Logo Images" />
          </li>
          <li>
            <img src={brand4} alt="Logo Images" />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
