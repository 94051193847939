import React, { Component } from "react";

import framestoreLogo from "../assets/images/eriks/Framestore_logo.png";
import allThingsMediaLogo from "../assets/images/eriks/atm_logo.png";
import leftFieldLabsLogo from "../assets/images/eriks/LFL_Logo.jpg";
import meowWolfLogo from "../assets/images/eriks/mw_logo.png";
import electricPlayhouseLogo from "../assets/images/eriks/electricplayhouse_logo.jpg";
import treeLogo from "../assets/images/eriks/tree_logo.jpg";
import vitaMotusLogo from "../assets/images/eriks/VitaMotus_Logo.jpg";
import teamRolfesLogo from "../assets/images/eriks/TeamRolfes_logo.png";
import {GoLinkExternal} from "react-icons/all";

class Brand extends Component {
  render() {
    const { branstyle } = this.props;
    return (
      <React.Fragment>
        <ul className={`brand-list ${branstyle}`}>
          <li>
            <a target="_blank" rel="noopener noreferrer"
            href="https://www.framestore.com/">
              <img src={framestoreLogo} alt="Logo Images"/>
          </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer"
               href="https://allthingsmedia.com/">
              <img src={allThingsMediaLogo} alt="Logo Images"/>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer"
               href="https://www.leftfieldlabs.com/">
              <img src={leftFieldLabsLogo} alt="Logo Images"/>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer"
               href="https://meowwolf.com/">
              <img src={meowWolfLogo} alt="Logo Images"/>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer"
               href="https://www.electricplayhouse.com/">
              <img src={electricPlayhouseLogo} alt="Logo Images"/>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer"
               href="https://vitamotus.com/">
              <img src={vitaMotusLogo} alt="Logo Images"/>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer"
               href="https://www.treeofficial.com/">
              <img src={treeLogo} alt="Logo Images"/>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer"
               href="https://rolfes.team/">
              <img src={teamRolfesLogo} alt="Logo Images"/>
            </a>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}

export default Brand;
