import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {GoLinkExternal} from "react-icons/all";
import certificate1 from "../../assets/images/eriks/CertificateUE51.jpg";

class TabsTwo extends Component {
  render() {
    let tab1 = "Main Skills",
      tab2 = "Awards",
      tab3 = "Experience",
      tab4 = "Education",
      tab5 = "Certificates";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                    <Tab>{tab4}</Tab>
                    <Tab>{tab5}</Tab>
                  </TabList>

                  {/* Main Skills */}
                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a target="_blank" rel="noopener noreferrer" href="https://www.unrealengine.com/en-US">
                            Unreal Engine <span> - Development/Programming <GoLinkExternal /></span>
                          </a>
                          <ul>
                            <li>Blueprints</li>
                            <li>C++</li>
                            <li>Mixed Reality Pipelines (VR, AR, XR)</li>
                            <li>Animation Scripting</li>
                            <li>Motion Matching</li>
                            <li>IK Setups</li>
                            <li>Procedural Animation</li>
                            <li>Character Controllers</li>
                            <li>Control Rigs</li>
                            <li>Motion Capture Pipelines</li>
                          </ul>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer" href="https://unity.com">
                            Unity Engine <span> - Development/Programming <GoLinkExternal /></span>
                          </a>
                          <ul>
                            <li>C#</li>
                            <li>Mobile Development (iOS, Android)</li>
                            <li>Mixed Reality Pipelines (VR, AR, XR)</li>
                            <li>Animation Scripting</li>
                          </ul>
                        </li>
                        <br/>
                        <li>
                          <a href="/service">
                            Web Development <span> - Scripting/Implementation</span>
                          </a>
                          <ul>
                            <li>Web Design</li>
                            <li>JavaScript</li>
                            <li>React</li>
                          </ul>
                        </li>
                        <br/>
                        <li>
                          <a href="/service">
                            Motion Capture<span> - Pipelines/Implementation</span>
                          </a>
                          <ul>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.movella.com/products/motion-capture">
                              <li>Xsens Motion Capture (Body) <GoLinkExternal /></li>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.rokoko.com/">
                              <li>Rokoko Motion Capture (Body) <GoLinkExternal /></li>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://optitrack.com/">
                              <li>OptiTrak Motion Capture (Body) <GoLinkExternal /></li>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://facewaretech.com/">
                              <li>Faceware Facial Motion Capture (Face) <GoLinkExternal /></li>
                            </a>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  {/* Awards */}
                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://theadvancedimagingsociety.com/creative-arts-lumiere-awards/">
                            Lumiere Award <GoLinkExternal/>
                          </a>
                          <span>"Tree" - New Reality Company - 2018</span>
                          BEST VR LOCATION BASED ANIMATED SHORT
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://variety.com/2018/artisans/awards/blade-runner-2049-coco-darla-anderson-pixar-take-major-prizes-at-lumiere-awards-1202696141/">
                            Variety Magazine Article <GoLinkExternal/>
                          </a>
                        </li>

                      </ul>
                    </div>
                  </TabPanel>

                  {/* Experience */}
                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.framestore.com/">
                            <b>Unreal Engine Developer - Framestore <GoLinkExternal/></b>
                          </a>
                          <span>January 2024 - May 2024</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>Worked a 5-month contract on an Unreal Engine project to develop movement systems for 
                            a character in an experimental experience. Designed custom setups for three walk systems: 
                            Motion Matching, traditional, and Procedural Animation. Implemented custom animation systems 
                            for IK, Foot Sliding, and Control Rig setups on the character.</span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://allthingsmedia.com/">
                            <b>Unity Engine Developer - All Things Media LLC <GoLinkExternal/></b>
                          </a>
                          <span>April 2023 - December 2023</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>As a member of a Unity development team, contributed to the creation and release of six 
                            Educational Activities for the McGraw Hill AR app on Apple and Android devices. I was solely 
                            responsible for programming the Coordinate Planes, Oregon Trail, Parthenon, Lowest Common 
                            Multiple, Trench, Ratios, and Silk Road activities within the app.</span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://vitamotus.com/">
                            <b>UE5 Developer - Vita Motus <GoLinkExternal/></b>
                          </a>
                          <span>January 2023 - April 2023</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>Developed a project for Vita Motus and their client to visualize a Show Room & Banquet 
                            Hall opening in Chicago. I was solely responsible for all code and moving parts of the 
                            presented experience.</span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.forager.earth/">
                            <b>UE5 Engineer - Forager <GoLinkExternal/></b>
                          </a>
                          <span>January 2023 - March 2023</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>Contributed to the development of an Interactive VR experience showcasing the first 
                            volumetric time-lapse in a game engine. Setup included a rapid-switching weather system for
                            alternating seasons and a random mushroom growth system. The project premiered at 
                            SXSW in 2023.</span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.leftfieldlabs.com/">
                            <b>Unity Developer & Unreal Developer - Left Field Labs <GoLinkExternal/></b>
                          </a>
                          <span>January 2022 - September 2022</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>Served as a remote Unity Developer for Left Field Labs, based in Los Angeles, CA, 
                            managing clients such as Google, Verizon, and Sony Pictures. Engaged in diverse projects 
                            spanning mobile VR, Android, and iOS platforms. Additionally, contributed to several Unreal 
                            Engine projects.</span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="http://www.sketchbot.tv/museum-of-untold-possibilities">
                            <b>UE4 Lead Programmer - Museum of Untold Possibilities <GoLinkExternal/></b>
                          </a>
                          <span>June 2021 - December 2021</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>Led development as an Unreal Engine 4 programmer and developer for the Epic-Mega grant 
                            VR project, Museum of Untold Possibilities.</span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="http://newreality.co/">
                            <b>UE4 Lead Programmer - New Reality Co. <GoLinkExternal/></b>
                          </a>
                          <span>September 2020 - May 2021</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>Led development as the Lead Unreal Engine 4 programmer and developer for an Epic-Mega 
                            grant project dedicated to rainforest preservation using XR devices.</span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.electricplayhouse.com/">
                            <b>UE4 Lead Programmer - Electric Playhouse <GoLinkExternal/></b>
                          </a>
                          <span>June 2020 - August 2020</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>Developed a project named "Bisti Beast" for the Albuquerque Museum of Natural History, 
                            focused on a dinosaur exhibit. Programmed the entire experience from start to finish, 
                            utilizing the Microsoft Kinect for tracking people in front of a projected landscape display 
                            featuring interactive roaming dinosaurs.</span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://meowwolf.com/">
                            <b>XR Programmer & Developer - Meow Wolf <GoLinkExternal/></b>
                          </a>
                          <span>June 2018 - April 2020</span>
                          <br/>
                          <span>Santa Fe, New Mexico</span>
                          <br/>
                          <span>Contributed to Virtual Reality and Augmented Reality projects for future exhibits at 
                            Meow Wolf's Las Vegas and Denver locations. Utilized both Unreal Engine and Unity Engine 
                            in the development process.</span>
                          <ul style={{listStyleType: "disc"}}>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://vimeo.com/260506208">
                                <b>The Atrium <GoLinkExternal/></b>
                              </a>
                              <span>Designed and built the animation rig for an IK-controlled VR Hamster and 
                                contributed to the development of scripts for character mechanics, AI functionality, 
                                and visual effects.</span>
                            </li>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://www.forbes.com/sites/jessedamiani/2020/02/19/the-top-35-xr-installations-of-2019/?sh=267d81a3ca08">
                                <b>The Garage <GoLinkExternal/></b>
                              </a>
                              <span>Developed and contributed to creating all four installations in the Garage AR 
                                experience, utilizing the Magic Leap headset.</span>
                            </li>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://www.youtube.com/watch?v=kl-xeN_PaI4">
                                <b>The Navigator <GoLinkExternal/></b>
                              </a>
                              <span>Contributed to developing the infrastructure and gameplay for the Navigator AR 
                                experience, utilizing the Magic Leap headset.</span>
                            </li>
                          </ul>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.treeofficial.com/">
                            <b>Lead Engine Programmer - TreeVR <GoLinkExternal/></b>
                          </a>
                          <span>October 2016 - February 2017</span>
                          <br/>
                          <span>Santa Cruz, CA (Remote)</span>
                          <br/>

                          <span>Developed IK solutions in Unreal Engine to simulate the movements of the "tree" 
                            character, enhancing the VR player's immersion. Coordinated with four art and effects teams 
                            worldwide to integrate their work into a UE4 project with source control management.
                            Provided on-site technical support and setup for the TreeVR experience at the Sundance Film 
                            Festival in 2017.</span>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.youtube.com/watch?v=ERffRXjTAqM">
                            <b>Trailer <GoLinkExternal/></b>
                          </a>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.youtube.com/watch?v=QLpDeZhObAs">
                            <b>Full Play-Through <GoLinkExternal/></b>
                          </a>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://rolfes.team/">
                            <b>Lead Engine Programmer - Team Rolfes <GoLinkExternal/></b>
                          </a>
                          <span>August 2015 - January 2019</span>
                          <br/>
                          <span>Santa Cruz, CA (Remote)</span>
                          <br/>
                          <span>Created innovative use-case applications of Unreal Engine and visual effects for music 
                            videos and game content. Established VR environments for live events and video streams 
                            while providing technical support to teams during these events.</span>
                          <ul style={{listStyleType: "disc"}}>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://en.wikipedia.org/wiki/Super_Deluxe">
                                <b>SuperDeluxe Live <GoLinkExternal/></b>
                              </a>
                            </li>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://www.youtube.com/watch?v=MZBRWqxmAjw">
                                <b>Rihanna Live VMA 2016 Background Video <GoLinkExternal/></b>
                              </a>
                            </li>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://www.youtube.com/watch?v=02k126v3Zu4">
                                <b>Amnesia Scanner - Chingy (featured on Adult Swim - Off The Air) <GoLinkExternal/></b>
                              </a>
                            </li>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://www.youtube.com/watch?v=u6dW0g4WgY0">
                                <b>Danny L Harle - Ashes of Love ft. Caroline Polachek <GoLinkExternal/></b>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.colum.edu/academics/media-arts/interactive-arts-and-media/">
                            <b>Teachers Aid (C#, C++) - Columbia College in Chicago - Interactive Arts & Media 
                              Department <GoLinkExternal/></b>
                          </a>
                          <span>September 2009 - September 2011</span>
                          <br/>
                          <span>Chicago, IL </span>
                          <br/>
                          <span>Served as a Teacher's Aid and programming tutor within the Interactive Arts and Media 
                            department. Tutored students in C#, C++, and general Object-Oriented Programming techniques. 
                            Additionally, managed maintenance of school computers in the computer lab.</span>
                        </li>
                        <br/>
                      </ul>
                    </div>
                  </TabPanel>

                  {/* Education */}
                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.colum.edu/academics/programs/programming">
                            Bachelor of Arts - Game Engine Programming <GoLinkExternal/>
                          </a>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.colum.edu/">
                            Columbia College in Chicago <GoLinkExternal/>
                          </a>
                          <span>Chicago, IL</span>
                          <br/>
                          <span>2012</span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.dvc.edu/">
                            Diablo Valley Community College <GoLinkExternal/>
                          </a>
                          <span>San Ramon, CA</span>
                          <br/>
                          <span>2005-2006</span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.dvc.edu/">
                            Monte Vista High School <GoLinkExternal/>
                          </a>
                          <span>Danville, CA</span>
                          <br/>
                          <span>2006</span>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  {/* Certificates */}
                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.udemy.com/certificate/UC-4d343f5e-c55a-41d5-8461-09f679e6fa8d/">
                            Unreal Engine 5 C++ Developer:Learn C++ & Make Video Games<GoLinkExternal/>
                          </a>
                          <span>Udemy Course</span>
                          2023
                          <br/>
                          <img className="w-50" src={certificate1} alt="certificate image"/>
                        </li>

                      </ul>
                    </div>
                  </TabPanel>
                  
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
